// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery

//= require popper
//= require summernote/summernote-bs4.min
//= require summernote-init
//= require_tree .

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// import ('jquery')
import "jquery"

// 追記
// require('jquery')

Rails.start()
Turbolinks.start()
ActiveStorage.start()


// document.addEventListener('DOMContentLoaded', () => {
//   console.log(document.getElementById('hello'));
// });

// 画像遅延読み込み
if ("loading" in HTMLImageElement.prototype) {
    var images = document.querySelectorAll('img[loading="lazy"]');
    var sources = document.querySelectorAll("source[data-srcset]");
    sources.forEach(function (source) {
        source.srcset = source.dataset.srcset;
    });
    images.forEach(function (img) {
        img.src = img.dataset.src;
    });
} else {
    var script = document.createElement("script");
    script.src = "/link/to/lazyload.js";
    document.body.appendChild(script);
}


// セレクトボックスが選択した時に自動的にformを送信
// document.addEventListener("turbolinks:load", function(){
//   $(".auto_select").change(function(){
//     $('#auto_form').submit();
//   });
// });

// //loding画面
// document.addEventListener("turbolinks:load", function(){
//   $("#loding").on("click", function(){
//     //   $("form").submit(); //フォーム実行
//       $("#overlay").fadeIn(500); //二度押しを防ぐloading表示
//       setTimeout(function(){
//           $("#overlay").fadeOut(500);
//       },120000);
//   });
// });

//loding画面
document.addEventListener("turbolinks:load", function(){
  $("#loding").on("click", function(){
      $("#overlay").fadeIn();
      setTimeout(function(){
          $("#overlay").fadeOut();
      },120000);
  });
});

// 画像のフェイドイン 
// $(document).on('turbolinks:load', function(){
//   load_effect();
//   $(window).scroll(function (){
//       scroll_effect();
//   });
// });

document.addEventListener("turbolinks:load", function(){
  load_effect();
  $(window).scroll(function (){
      scroll_effect();
  });
});

//ふわっとロード
function load_effect(){
  var tt = $(window).scrollTop();
  var hh = $(window).height();
  $('.load-fade').each(function(){
      var yy = $(this).offset().top;
      if (tt > yy - hh){
          $(this).addClass('done');
      }
  });
  $('.load-up').each(function(){
      var yy = $(this).offset().top;
      if (tt > yy - hh){
          $(this).addClass('done');
      }
  });
}

//ふわっとスクロール
function scroll_effect(){
  var tt = $(window).scrollTop();
  var hh = $(window).height();
  $('.scroll-fade').each(function(){
      var yy = $(this).offset().top+100;
      if (tt > yy - hh){
          $(this).addClass('done');
      }
  });
  $('.scroll-up').each(function(){
      var yy = $(this).offset().top+100;
      if (tt > yy - hh){
          $(this).addClass('done');
      }
  });
}

//ドロップダウン（ナビバー）
document.addEventListener("turbolinks:load", function(){
  $("#dropdown_menu").after().hide();
  $("#dropdown").click(function() {
    $("#dropdown_menu").toggleClass("open").slideToggle("normal", function() {
       
    });
  });
});

// ハンバガーメニュー
document.addEventListener("turbolinks:load", function(){
  (function($) {
      var $nav = $('#navArea');
      var $btn = $('.toggle_btn');
      var $mask = $('#mask');
      var open = 'open'; // class
      // menu open close
      $btn.on( 'click', function() {
      if ( ! $nav.hasClass( open ) ) {
      $nav.addClass( open );
      } else {
      $nav.removeClass( open );
      }
  });
  // mask close
  $mask.on('click', function() {
      $nav.removeClass( open );
      });
  } )(jQuery);
});

// 画像プレビュー用
document.addEventListener("turbolinks:load", function(){
  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('#img_prev').attr('src', e.target.result);
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
  $("#p_img").change(function(){
    readURL(this);
  });
});

// 画像のプレビューを消す
document.addEventListener("turbolinks:load", function(){
  $(".delete_btn").eq(0).click(function(){
    $("#img_prev").fadeToggle(300);
  });
});

document.addEventListener("turbolinks:load", function(){
  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('#img_prev2').attr('src', e.target.result);
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
  $("#p_img2").change(function(){
    readURL(this);
  });
});

// 画像のプレビューを消す
document.addEventListener("turbolinks:load", function(){
  $(".delete_btn").eq(1).click(function(){
    $("#img_prev2").fadeToggle(300);
  });
});